<template>
  <QuestionBase
      :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
      :valid="!!data"
      :disabled-valid="false"
  >
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :noSave="noSave"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputBasic from "../../../inputs/InputBasic";

export default {
  name: 'LeaseEndYear',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'How many years are left on the lease?',
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: 'mortgage_property_save',
        saveField: 'lease_end_year',
        objectId: this.objectId
      },
      args: {
        placeholder: 'e.g. 12',
        label: null,
        type: 'number',
        skippable: false,
        required: true,
        inputClass: 'field-50',
        appendText: 'Years'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>