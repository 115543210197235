<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">General</h3>

    <AddressFound v-model="form.address"
                  :form="form" />

    <Type v-model="form.type" />

    <div v-if="form.type==='House'"
         class="question-box with-arrow">
      <DescriptionHouse v-model="form.description" />
    </div>

    <div v-if="form.type==='Maisonette'"
         class="question-box with-arrow">
      <DescriptionMaisonette v-model="form.description" />
    </div>

    <div v-if="form.type==='Flat'"
         class="question-box with-arrow">
      <DescriptionFlat v-model="form.description" />
    </div>

    <Tenure v-model="form.tenure" />

    <div v-if="form.tenure==='Leasehold'"
         class="question-box with-arrow">
      <LeaseEndYear v-model="form.lease_end_year" />

      <ServiceCharge v-model="form.service_charge" />

      <GroundRent v-model="form.ground_rent" />
    </div>

    <Usage v-model="form.usage" />

    <div v-if="form.usage === 'Mainly Residential'"
         class="question-box with-arrow">
      <PercentageResidential v-model="form.usage_mainly_residential" />
    </div>

    <div v-if="form.usage === 'HMO'"
         class="question-box with-arrow">
      <HmoMonthlyRent v-model="form.rent" />

      <HmoLicenceRequired v-model="form.usage_hmo" />
    </div>

<!--    <div v-if="form.usage === 'Buy-to-Let'"-->
<!--         class="question-box with-arrow">-->
<!--      <BuyToLetMonthlyRent v-model="form.rent" />-->
<!--    </div>-->

    <div v-if="form.usage === 'Mixed'"
         class="question-box with-arrow">
      <UsageDetails v-model="form.usage_mixed" />

<!--      <MixedMonthlyRent v-model="form.rent" />-->
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {statusOptions} from "../../../question/options/statusOptions";

import AddressFound from "../../../question/questions/mortgage/property/AddressFound";
import Type from "../../../question/questions/mortgage/property/Type";
import DescriptionHouse from "../../../question/questions/mortgage/property/DescriptionHouse";
import DescriptionMaisonette from "../../../question/questions/mortgage/property/DescriptionMaisonette";
import DescriptionFlat from "../../../question/questions/mortgage/property/DescriptionFlat";
import Tenure from "../../../question/questions/mortgage/property/Tenure";
import LeaseEndYear from "../../../question/questions/mortgage/property/LeaseEndYear";
import ServiceCharge from "../../../question/questions/mortgage/property/ServiceCharge";
import GroundRent from "../../../question/questions/mortgage/property/GroundRent";
import Usage from "../../../question/questions/mortgage/property/Usage";

import PercentageResidential from "../../../question/questions/mortgage/property/PercentageResidential";
import HmoMonthlyRent from "../../../question/questions/mortgage/property/HmoMonthlyRent";
import HmoLicenceRequired from "../../../question/questions/mortgage/property/HmoLicenceRequired";
// import BuyToLetMonthlyRent from "../../../question/questions/mortgage/property/BuyToLetMonthlyRent";
import UsageDetails from "../../../question/questions/mortgage/property/UsageDetails";
// import MixedMonthlyRent from "../../../question/questions/mortgage/property/MixedMonthlyRent";

export default {
  name: 'General',
  mixins: [
    fieldsetHelpers,
    statusOptions
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    Type,
    AddressFound,
    DescriptionHouse,
    DescriptionMaisonette,
    DescriptionFlat,
    Tenure,
    LeaseEndYear,
    ServiceCharge,
    GroundRent,
    Usage,
    PercentageResidential,
    HmoMonthlyRent,
    HmoLicenceRequired,
    // BuyToLetMonthlyRent,
    UsageDetails,
    // MixedMonthlyRent,
  },
  data() {
    return {
      label: 'General', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_property_details.mp4'
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
