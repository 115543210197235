<template>
  <QuestionBase
      :question="question"
      :errors="[...serverErrors, ...jsErrors]"
      :valid="!!data">
    <InputRadio
        v-model="data"
        :options="optionsPropertyHouseDescription"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        class="radio-options-wide"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {propertyOptions} from "../../../options/propertyOptions";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'DescriptionHouse',
  mixins: [propertyOptions, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'How would you describe the house?',
        subTitle: null,
        tip: `<p><strong>Detached</strong> - A detached house is a stand-alone residential structure that does not share outside walls with another house or building.<br><br><strong>Semi detached</strong> - A dwelling attached to another building or dwelling by one common party wall. Often each house's layout is a mirror image of the other.<br><br><strong>Link-detached</strong> - A link-detached property or house is a term given to residential units that share no common walls with another house or dwelling. They are, however, typically linked together by a garage.<br><br><strong>Terraced house</strong> - A house annexed to another house on two sides forming part of a row of similar houses each with its own frontage to a public road.<br><br><strong>Mid Terrace</strong> - used to describe a house situated in the middle of a row of similar houses.<br><br><strong>End of Terrace</strong> used to describe a house situated at the end of a row of houses only joined on one side to a joining property.</p>`,
      },
      db: {
        saveLocation: 'mortgage_property_save',
        saveField: 'description',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
