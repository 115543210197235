<template>
  <div
      class="question-multipart-wrapper d-flex flex-wrap align-items-end"
  >
    <QuestionBase
        :question="question"
        :valid="!!data"
        :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
        :disabled-valid="false"
    >
      <InputDateSplit
          v-if="data || data === null"
          v-model="data" :db="db"
          :args="args"
          :skipped="skipped"
          :no-save="noSave"
          :errors-post="errorsPost"
          @skipped="skipped=$event"
          @serverErrors="serverErrors=$event"
          @jsErrors="jsErrors=$event"
      />
    </QuestionBase>
    <div class="alert-danger rounded-lg p-24 mb-12 mt-12"><h5>Disclaimer</h5> We always work to ensure that your mortgage offer is issued as quickly as possible, although ultimately timescales are largely dependant on the lender / solicitor. For this reason we cannot guarantee your offer will be issue within a particular timeframe.<br><br><strong>(On average mortgages are offered within 21 days)</strong></div>
  </div>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import QuestionBase from "../../../QuestionBase";
import InputDateSplit from "../../../inputs/InputDateSplit";

export default {
  name: 'DeadlineDate',
  mixins: [saveHelpers, questionLoadHelpers],
  components: {
    InputDateSplit,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title:  'Please confirm the deadline date:',
        subTitle:  null,
        tip: null
      },
      db: {
        saveLocation: 'mortgage_property_save',
        saveField: 'deadline_date',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>