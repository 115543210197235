<template>
  <QuestionnaireBase ref="questionnaireBase" :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>

      <component
          :is="show"
          :key="1"
          :ref="show"
          v-model="form"
      />

      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />

    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import General from "../../../components/common/questionnaires/fieldsets/mortgage/property/General";
import Specification from "../../../components/common/questionnaires/fieldsets/mortgage/property/Specification";
import Considerations from "../../../components/common/questionnaires/fieldsets/mortgage/property/Considerations";
import Access from "../../../components/common/questionnaires/fieldsets/mortgage/property/Access";
import Financials from "../../../components/common/questionnaires/fieldsets/mortgage/property/Financials";

export default {
  name: "MortgageProperty",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    General,
    Specification,
    Considerations,
    Access,
    Financials
  },
  data() {
    return {
      name: 'MortgageProperty',
      title: "Property Details",
      url: 'mortgage_property',
      order: [ // imported components required here to load
        'General',
        'Specification',
        'Considerations',
        'Access',
        'Financials',
      ],
      expiryAccessCodeOnFinish: true
    };
  }
};
</script>
