<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data">
    <InputBasic v-model="data"
                :args="args"
                :db="db"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputBasic from "../../../inputs/InputBasic";

export default {
  name: 'AccessName',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    accessProvider: {
      type: String,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: this.accessProvider === 'Estate Agent' ? 'What is the agents name?' : this.accessProvider === 'Builder / Developer' ? 'What is the builders name?' : this.accessProvider === 'Vendor' ? 'What is the vendors name?' : this.accessProvider === 'Letting Agent' ? 'What is the agents name?' : this.accessProvider === 'Tenant' ? 'What is the tenants name?' : this.accessProvider === 'Yourself' ? 'What is your name?' : this.accessProvider === 'Other' ? 'Who should surveyor contact?' : 'Who should surveyor contact?',
        subTitle: null,
        tip: null
      }
    },
    placeholder() {
      if (this.accessProvider === 'Vendor' || this.accessProvider === 'Tenant' || this.accessProvider === 'Other' || this.accessProvider === 'Yourself') {
        return 'e.g. Joe Bloggs'
      } else if (this.accessProvider === 'Builder / Developer') {
        return 'AB Builders'
      }
      return 'Dixons Stafford'
    },
    args() {
      return {
        placeholder: this.placeholder,
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-66'
      }
    },
  },
  data() {
    return {
      db: {
        saveLocation: 'mortgage_property_save',
        saveField: 'access_name'
      },

      serverErrors: [],
      jsErrors: [],
      skipped: false,
      required: false,
    }
  }
}
</script>