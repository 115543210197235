<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Specification</h3>

    <New
        v-model="form.new"/>

    <div
        v-if="form.new"
        class="question-box with-arrow">

      <UnderConstruction
          v-model="form.under_construction"/>

      <HasNHBC
          v-model="form.nhbc"/>
    </div>

    <div
        v-if="form.new === false"
        class="question-box with-arrow">
      <Age
          v-model="form.age"/>
    </div>

    <Floors
        v-if="form.type === 'Flat'"
        v-model="form.floors"/>

    <Floor
        v-if="form.type === 'Flat'"
        v-model="form.floor"/>


    <Bedrooms
        v-model="form.bedrooms"/>

    <ReceptionRooms
        v-model="form.receptions"/>

    <Bathrooms
        v-model="form.bathrooms"/>

    <Wc
        v-model="form.wc"/>

    <Garage
        v-model="form.garage"/>

    <Parking
        v-model="form.parking"/>

    <Cellar
        v-model="form.cellar"/>

    <Has1Acre
        v-model="form.land"/>

    <template  v-if="form.type === 'Flat'">
      <Lift
          v-model="form.lift"/>

      <Balcony
          v-model="form.balcony"/>
    </template>


  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {statusOptions} from "../../../question/options/statusOptions";

import New from "../../../question/questions/mortgage/property/New";
import UnderConstruction from "../../../question/questions/mortgage/property/UnderConstruction";
import HasNHBC from "../../../question/questions/mortgage/property/HasNHBC";
import Floors from "../../../question/questions/mortgage/property/Floors";
import Floor from "../../../question/questions/mortgage/property/Floor";
import Age from "../../../question/questions/mortgage/property/Age";
import Bedrooms from "../../../question/questions/mortgage/property/Bedrooms";
import ReceptionRooms from "../../../question/questions/mortgage/property/ReceptionRooms";
import Bathrooms from "../../../question/questions/mortgage/property/Bathrooms";
import Wc from "../../../question/questions/mortgage/property/Wc";
import Garage from "../../../question/questions/mortgage/property/Garage";
import Parking from "../../../question/questions/mortgage/property/Parking";
import Cellar from "../../../question/questions/mortgage/property/Cellar";
import Has1Acre from "../../../question/questions/mortgage/property/Has1Acre";
import Lift from "../../../question/questions/mortgage/property/Lift";
import Balcony from "../../../question/questions/mortgage/property/Balcony";

export default {
  name: 'Specification',
  mixins: [
    fieldsetHelpers,
    statusOptions
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    New,
    UnderConstruction,
    HasNHBC,
    Floors,
    Floor,
    Age,
    Bedrooms,
    ReceptionRooms,
    Bathrooms,
    Wc,
    Garage,
    Parking,
    Cellar,
    Has1Acre,
    Lift,
    Balcony,
  },
  data() {
    return {
      label: 'Specification', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_property_specification.mp4'

    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
