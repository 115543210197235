<template>
  <div class="fieldset">

    <h3 class="fieldset-heading">Access</h3>

    <AccessProvider v-model="form.access"/>
    <AccessName v-model="form.access_name"
                :accessProvider="form.access"/>
    <div v-if="['Letting Agent', 'Estate Agent'].includes(form.access)">
      <AccessNameAddress
          v-model="form.access_name_address"
          :form="form"/>
    </div>
    <AccessPhone v-model="form.access_phone"/>
    <AccessEmail v-model="form.access_email"/>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {statusOptions} from "../../../question/options/statusOptions";

import AccessProvider from "../../../question/questions/mortgage/property/AccessProvider";
import AccessName from "../../../question/questions/mortgage/property/AccessName";
import AccessNameAddress from "../../../question/questions/mortgage/property/AccessNameAddress";
import AccessPhone from "../../../question/questions/mortgage/property/AccessPhone";
import AccessEmail from "../../../question/questions/mortgage/property/AccessEmail";

export default {
  name: 'Access',
  mixins: [
    fieldsetHelpers,
    statusOptions
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AccessProvider,
    AccessName,
    AccessNameAddress,
    AccessPhone,
    AccessEmail,
  },
  data() {
    return {
      label: 'Access', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_property_access.mp4'
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
