<template>
  <QuestionBase
      :question="question"
      :errors="[...serverErrors, ...jsErrors]"
      :valid="!!data">
    <InputRadio
        v-model="data"
        :options="optionsPropertyType"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        class="radio-options-wide"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {propertyOptions} from "../../../options/propertyOptions";
import { fieldsetLoader } from "@/mixins/fieldsetLoader";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'Type',
  mixins: [propertyOptions, questionLoadHelpers, fieldsetLoader],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What type of property are you mortgaging?',
        subTitle: 'For more details on property types, click the tip icon (right).',
        tip: '<p><strong>Flat</strong> - A flat is a separate and self-contained premise constructed or adapted for ' +
            'use for residential purposes and forming part of a building from some other part of which it is divided ' +
            'horizontally. Flats have to be contained within a dwelling with at least two storeys.' +
            '<br><br><strong>Maisonette</strong> - French term derivation meaning a little house. In UK used to ' +
            'describe a set of rooms for living in typically on two storeys as part of a larger building with a ' +
            'separate entrance from rest of the building. Can be one level or split level.' +
            '<br><br><strong> Bungalow</strong> - Originates from India and Bengal. Described simply as a low ' +
            'house having one storey.   <br><br><strong>House</strong> - A house is any other self-contained ' +
            'premise constructed or adapted for use for residential purposes.</p>'
      },
      db: {
        saveLocation: 'mortgage_property_save',
        saveField: 'type'
        // callbackFunction: this.adjustFieldSets
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  },
  methods: {
    async adjustFieldSets() {
      // adds or removes field sets on question input
      if (this.data === 'House') {
        await this.removeFieldSets(['Specification', 'Considerations'])
        await this.addFieldSets([['Access', 3], ['Financials', 4]])
      } else {
        await this.removeFieldSets(['Access', 'Financials'])
        await this.addFieldSets([['Specification', 1], ['Considerations', 2]])
      }
    }
  }
}
</script>
