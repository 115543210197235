<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Considerations</h3>

    <NonStandardConstruction v-model="form.non_standard_construction" />

    <div v-if="form.non_standard_construction"
         class="question-box with-arrow">
      <ConstructionType v-model="form.construction_type" />
    </div>

    <NearCommercial v-model="form.near_commercial" />

    <PastFlooding v-model="form.flooded" />

    <CoastalErosion v-model="form.corrosion" />

    <PastSubsidence v-model="form.subsidence" />

    <Habitable v-model="form.habitable" />

    <HasEW1S v-model="form.ew1s" />

    <ListedBuilding v-model="form.listed" />

    <AgriculturalRestrictions v-model="form.restrictions" />

    <ExLocalAuthority v-model="form.authority"/>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {statusOptions} from "../../../question/options/statusOptions";

import NonStandardConstruction from "../../../question/questions/mortgage/property/NonStandardConstruction";
import ConstructionType from "../../../question/questions/mortgage/property/ConstructionType";
import NearCommercial from "../../../question/questions/mortgage/property/NearCommercial";
import PastFlooding from "../../../question/questions/mortgage/property/PastFlooding";
import CoastalErosion from "../../../question/questions/mortgage/property/CoastalErosion";
import PastSubsidence from "../../../question/questions/mortgage/property/PastSubsidence";
import Habitable from "../../../question/questions/mortgage/property/Habitable";
import HasEW1S from "../../../question/questions/mortgage/property/HasEW1S";
import ListedBuilding from "../../../question/questions/mortgage/property/ListedBuilding";
import AgriculturalRestrictions from "../../../question/questions/mortgage/property/AgriculturalRestrictions";
import ExLocalAuthority from "@/components/common/questionnaires/question/questions/mortgage/property/ExLocalAuthority";

export default {
  name: 'Considerations',
  mixins: [
    fieldsetHelpers,
    statusOptions
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    ExLocalAuthority,
    NonStandardConstruction,
    ConstructionType,
    NearCommercial,
    PastFlooding,
    CoastalErosion,
    PastSubsidence,
    Habitable,
    HasEW1S,
    ListedBuilding,
    AgriculturalRestrictions,
  },
  data() {
    return {
      label: 'Considerations', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_property_considerations.mp4'

    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
