<template>
  <InputAddress
      v-model="data"
      :db-override="db"
      :question-override="question"
      :jsErrorsData="jsErrors"
      @jsErrors="jsErrors=$event"
  />
</template>

<script>
import InputAddress from "../../../inputs/InputAddress";

export default {
  name: 'AddressFound',
  components: {InputAddress},
  props: {
    value: {
      type: Object,
      required: false
    },
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'What is the address of the property to be mortgaged?',
        subTitle: `If you don't know the full address or if the property isn't built yet, click 'Enter Manually' and enter a partial address.`
      },
      db: {
        saveLocation: 'mortgage_property_address',
        saveField: 'address',
        deleteLocation: 'mortgage_property_address'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: this.$parent.$parent.$data.show
    }
  }
}
</script>