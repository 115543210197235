<template>
  <QuestionBase
      :errors="[...serverErrors, ...jsErrors]"
      :question="question"
      :valid="!!data">
    <InputRadio
        v-model="data"
        :args="args"
        :db="db"
        :options="optionsPropertyFlatDescription"
        :skipped="skipped"
        class="radio-options-wide"
        @jsErrors="jsErrors=$event"
        @serverErrors="serverErrors=$event"
        @skipped="skipped=$event"/>
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {propertyOptions} from "../../../options/propertyOptions";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'DescriptionMaisonette',
  mixins: [
    propertyOptions,
    questionLoadHelpers
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Is the maisonette built or converted?',
        subTitle: 'Click the \'Tip\' icon for more information about different property types.',
      },
      db: {
        saveLocation: 'mortgage_property_save',
        saveField: 'description',
      },
      args: {
        skippable: true,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
