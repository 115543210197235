export const propertyOptions = {
    data () {
        return {
            optionsPropertyAccess: [
                {
                  label: 'Estate Agent',
                  value: 'Estate Agent',
                },
                {
                  label: 'Builder / Developer',
                  value: 'Builder / Developer',
                },
                {
                  label: 'Vendor',
                  value: 'Vendor',
                },
                {
                  label: 'Letting Agent',
                  value: 'Letting Agent',
                },
                {
                  label: 'Tenant',
                  value: 'Tenant',
                },
                {
                  label: 'Yourself',
                  value: 'Yourself',
                },
                {
                  label: 'Other',
                  value: 'Other',
                }
              ],
              optionsPropertyType: [
                {
                  label: 'House',
                  value: 'House',
                },
                {
                  label: 'Bungalow',
                  value: 'Bungalow',
                },
                {
                  label: 'Flat',
                  value: 'Flat',
                },
                {
                  label: 'Maisonette',
                  value: 'Maisonette',
                }
              ],
              optionsPropertyFlatDescription: [
                {
                  label: 'Purpose Built',
                  value: 'Purpose Built',
                },
                {
                  label: 'Converted',
                  value: 'Converted',
                }
              ],
              optionsPropertyHouseDescription: [
                {
                  label: 'Detached',
                  value: 'Detached',
                },
                {
                  label: 'Semi-Detached',
                  value: 'Semi-Detached',
                },
                {
                  label: 'Terraced',
                  value: 'Terraced',
                },
                {
                  label: 'Mid-Terraced',
                  value: 'Mid-Terraced',
                },
                {
                  label: 'End-Terraced',
                  value: 'End-Terraced',
                },
                {
                  label: 'Other',
                  value: 'Other',
                }
              ],
              optionsPropertyTenure: [
                {
                  label: 'Freehold',
                  value: 'Freehold',
                },
                {
                  label: 'Leasehold',
                  value: 'Leasehold',
                },
                {
                  label: 'Commonhold',
                  value: 'Commonhold',
                },
                {
                  label: 'Other',
                  value: 'Other',
                }
              ],
              optionsPropertyAge: [
                {
                  label: '1 - 2 years',
                  value: '1-2years',
                },
                {
                  label: '3 - 10 years',
                  value: '3-10years',
                },
                {
                  label: '11 - 30 years',
                  value: '11-30years',
                },
                {
                  label: '31 - 60 years',
                  value: '31-60years',
                },
                {
                  label: '61 - 120 years',
                  value: '61-120years',
                },
                {
                  label: '120 years+',
                  value: '120years+',
                }
              ],
              optionsPropertyUsage: [
                {
                  label: 'Residential',
                  value: 'Residential',
                },
                {
                  label: 'Mainly Residential',
                  value: 'Mainly Residential',
                },
                {
                  label: 'Buy-to-Let',
                  value: 'Buy-to-Let',
                },
                {
                  label: 'HMO',
                  value: 'HMO',
                },
                {
                  label: 'Mixed',
                  value: 'Mixed',
                }
              ],
              optionsPropertyConstructionType: [
                {
                  label: 'Airey',
                  value: 'Airey',
                },
                {
                  label: 'Arrowhead',
                  value: 'Arrowhead',
                },
                {
                  label: 'Boot',
                  value: 'Boot',
                },
                {
                  label: 'Boswell',
                  value: 'Boswell',
                },
                {
                  label: 'British Iron and Steel Federation House',
                  value: 'British Iron and Steel Federation House',
                },
                {
                  label: 'Cornish Unit Type 1',
                  value: 'Cornish Unit Type 1',
                },
                {
                  label: 'Cornish Unit Type 2',
                  value: 'Cornish Unit Type 2',
                },
                {
                  label: 'Dorlonco',
                  value: 'Dorlonco',
                },
                {
                  label: 'Dorran',
                  value: 'Dorran',
                },
                {
                  label: 'Dyke Construction',
                  value: 'Dyke Construction',
                },
                {
                  label: 'Gregory',
                  value: 'Gregory',
                },
                {
                  label: 'Hawthorn Leslie',
                  value: 'Hawthorn Leslie',
                },
                {
                  label: 'Howard',
                  value: 'Howard',
                },
                {
                  label: 'Laing Easyform',
                  value: 'Laing Easyform',
                },
                {
                  label: 'Lowton Cubit',
                  value: 'Lowton Cubit',
                },
                {
                  label: 'Myton',
                  value: 'Myton',
                },
                {
                  label: 'Mac-Girling',
                  value: 'Mac-Girling',
                },
                {
                  label: 'Newland',
                  value: 'Newland',
                },
                {
                  label: 'Orlit',
                  value: 'Orlit',
                },
                {
                  label: 'Parkinson',
                  value: 'Parkinson',
                },
                {
                  label: 'Reema',
                  value: 'Reema',
                },
                {
                  label: 'Reema conclad',
                  value: 'Reema conclad',
                },
                {
                  label: 'Stent',
                  value: 'Stent',
                },
                {
                  label: 'Smith',
                  value: 'Smith',
                },
                {
                  label: 'Schindler',
                  value: 'Schindler',
                },
                {
                  label: 'Stonecret',
                  value: 'Stonecret',
                },
                {
                  label: 'Swedish timber dwelling',
                  value: 'Swedish timber dwelling',
                },
                {
                  label: 'Tarran Temporary Bungalow',
                  value: 'Tarran Temporary Bungalow',
                },
                {
                  label: 'Tee Beam',
                  value: 'Tee Beam',
                },
                {
                  label: 'Thorncliffe',
                  value: 'Thorncliffe',
                },
                {
                  label: 'Unity Type 1',
                  value: 'Unity Type 1',
                },
                {
                  label: 'Unity Type 2',
                  value: 'Unity Type 2',
                },
                {
                  label: 'Ulster Cottage',
                  value: 'Ulster Cottage',
                },
                {
                  label: 'Undertown',
                  value: 'Undertown',
                },
                {
                  label: 'Unitroy',
                  value: 'Unitroy',
                },
                {
                  label: 'Wates',
                  value: 'Wates',
                },
                {
                  label: 'Wessex',
                  value: 'Wessex',
                },
                {
                  label: 'Wimpey No Fines',
                  value: 'Wimpey No Fines',
                },
                {
                  label: 'Woolaway',
                  value: 'Woolaway',
                },
                {
                  label: 'Winget',
                  value: 'Winget',
                }
              ],
              optionsPropertyVendor: [
                {
                  label: 'Estate Agent',
                  value: 'Estate Agent',
                },
                {
                  label: 'Builder / Developer',
                  value: 'Builder / Developer',
                },
                {
                  label: 'Family',
                  value: 'Family',
                },
                {
                  label: 'Private Purchase',
                  value: 'Private Purchase',
                }
              ],
              optionsPropertyDeadline: [
                {
                  label: 'No Deadline',
                  value: 'No Deadline',
                },
                {
                  label: '28 Days',
                  value: '28 Days',
                },
                {
                  label: 'Specific Date',
                  value: 'Specific Date',
                }
              ]

        }
    }
}
