export const fieldsetLoader = {
    methods: {
        getBase(parentIn = null) {
            // dynamically get the parent to QuestionnaireBase, this should be where the relevant show data is stored
            // let component = null
            let parent = parentIn
            if (!parent) {
                parent = this.$parent
            }

            if ('$data' in parent && 'show' in parent.$data && 'order' in parent.$data) {
                return parent
            }
            return this.getBase(parent.$parent)
        },
        async timeout(ms) {
            // pass a time in milliseconds to this function
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async removeFieldSets(components) {
            /* Removes field sets from questionnaire: components is array of Strings (component names)  */

            // get questionnaire base component
            let base = this.getBase()

            for (let i = 0; i < components.length; i++) {
                let name = components[i]
                let foundIndex = base.$data.order.indexOf(name)

                // get fieldset index
                if (foundIndex >= 0) {
                    // remove fieldset
                    base.$data.order.splice(foundIndex, 1)
                }

                this.$store.commit('fieldSetRemove', name)
            }

            return Promise.resolve(true)
        },
        async addFieldSets(components) {
            console.log('add fieldset', components)
            /* Adds field sets from questionnaire: components is array of arrays with [component names (String), index (to insert fieldset at)]  */

            // get questionnaire base component
            let base = this.getBase()

            for (let i = 0; i < components.length; i++) {
                let name = components[i][0]
                let index = components[i][1]

                // if fieldset is already in order then remove first
                let foundIndex = base.$data.order.indexOf(name)

                if (foundIndex >= 0) {
                    // remove fieldset
                    base.$data.order.splice(foundIndex, 1)
                    this.$store.commit('fieldSetRemove', name)
                }

                // insert new fieldset
                base.$data.order.splice(index - 1, 0, name)

                // add fieldset here

                base.$data.load = name

                await this.timeout(50)

            }
            // load in fake fieldset (TODO: fix)
            base.$data.load = 'test'
            return Promise.resolve(true)
        },
    }
}
