<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Financials</h3>

    <Price v-model="form.price" />

    <Deadline v-model="form.deadline" />

    <div v-if="form.deadline === '28 Days' || form.deadline === 'Specific Date'"
         class="question-box with-arrow">
      <DeadlineDate v-model="form.deadline_date" />
    </div>

    <VendorType v-model="form.vendor" />

    <Concessionary v-model="form.concessionary" />

    <div v-if="form.concessionary"
         class="question-box with-arrow">
      <ConcessionaryDetails v-model="form.concessionary_details" />
    </div>

    <Incentives v-model="form.incentives" />

    <div v-if="form.incentives"
         class="question-box with-arrow">
      <IncentivesDetails v-model="form.incentives_details" />
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {statusOptions} from "../../../question/options/statusOptions";

import Price from "../../../question/questions/mortgage/property/Price";
import Deadline from "../../../question/questions/mortgage/property/Deadline";
import DeadlineDate from "../../../question/questions/mortgage/property/DeadlineDate";
import VendorType from "../../../question/questions/mortgage/property/VendorType";
import Concessionary from "../../../question/questions/mortgage/property/Concessionary";
import ConcessionaryDetails from "../../../question/questions/mortgage/property/ConcessionaryDetails";
import Incentives from "../../../question/questions/mortgage/property/Incentives";
import IncentivesDetails from "../../../question/questions/mortgage/property/IncentivesDetails";

export default {
  name: 'Financials',
  mixins: [
    fieldsetHelpers,
    statusOptions
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    Price,
    Deadline,
    DeadlineDate,
    VendorType,
    Concessionary,
    ConcessionaryDetails,
    Incentives,
    IncentivesDetails,
  },
  data() {
    return {
      label: 'Financials', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_property_finance_details.mp4'
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
